import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  const handleMouseMove = (event) => {
    const videoElement = document.getElementById('video');
    if (videoElement) {
      const rect = videoElement.getBoundingClientRect();
      const videoCenterX = rect.left + rect.width / 2;
      const videoCenterY = rect.top + rect.height / 2;
      const x = (event.clientX - videoCenterX) / -50;
      const y = (event.clientY - videoCenterY) / 50;
      setPosition({ x, y });
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleYesClick = () => {
    setIsOverlayVisible(false);
    const audio = new Audio(`${process.env.PUBLIC_URL}/void.mp3`);
    audio.volume = 0.5;
    audio.play();
  };

  return (
    <div className="App">
      {isOverlayVisible && (
        <div className="overlay">
          <div className="overlay-box">
            <p>Are you prepared to witness the Eye of Sauron?</p>
            <button onClick={handleYesClick}>Yes</button>
          </div>
        </div>
      )}

      {/* Floating Fire Particles */}
      <div className="particles-container">
        {[...Array(20)].map((_, i) => (
          <div key={i} className="particle" />
        ))}
      </div>

      {/* Video Section */}
      <section className="video-section">
        <div
          className="video-container"
          style={{
            transform: `rotateX(${position.y}deg) rotateY(${position.x}deg)`,
          }}
        >
          <video id="video" src={`${process.env.PUBLIC_URL}/sauron.mp4`} autoPlay loop muted />
        </div>
      </section>

      {/* Description Section */}
      <section className="description-section">
        <p className="description-text">
          The Eye of Sauron, a symbol of omnipresent power and vigilant watchfulness, represents the dark lord's ability to see all.
          From atop Barad-dûr, this fiery eye scans Middle-earth, searching for the One Ring and any who dare to oppose him.
          Its piercing gaze is both feared and respected, embodying the essence of Sauron's relentless pursuit of domination.
          <br></br>
          <br></br>
          CA: 6MHE24sC2RzjfuBtXyPz8SpcjYrVoWBh9kVe7evNpump
        </p>
      </section>

      {/* Footer Icons */}
      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="logo" />
      <div className="right-icons">
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="Logo"
          className="logo"
        />
        <a href="https://x.com/saurononsol" target="_blank" rel="noopener noreferrer">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/x-social-media-white-icon.png"
            alt="X Icon"
            className="icon"
          />
        </a>

        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="Pump Logo"
          className="logo"
        />
        <a href="https://pump.fun/6MHE24sC2RzjfuBtXyPz8SpcjYrVoWBh9kVe7evNpump" target="_blank" rel="noopener noreferrer">
          <img
            src="https://pump.fun/_next/image?url=%2Flogo.png&w=64&q=75"
            alt="Pump Icon"
            className="icon"
          />
        </a>
      </div>
    </div>
  );
}

export default App;
